<template>
  <div>
    <apexchart
      type="radialBar"
      data
      v-if="graph2.series"
      :options="graph2"
      :series="graph2.series"
    ></apexchart>

  </div>
</template>

<script>
export default {
  name: "graphGoal",
  props: ["vendas", "metas"],
  data: () => {
    return {
      graph2: null,
    };
  },
  created() {
    this.getDataGoal();
    this.calPercent();
  },

  methods: {
    getDataGoal() {
      this.graph2 = {
        series: [],
        colors: ["#379EFC"],
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#333",
              startAngle: -90,
              endAngle: 90
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                fontSize: "20px",
                show: true
              }
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            gradientToColors: ["#57B45E"],
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: "butt"
        },
        labels: ["Progress"]
      };
    },
    calPercent() {
      
      if(this.metas  && this.vendas ){
       this.graph2.series.push(((this.vendas / this.metas)* 100).toFixed(2)); 
      }else {
      this.graph2.series.push(0)
      }
       
    }
  }
};
</script>

<style>
</style>