<template>
  <v-container v-if="detail">
    <v-row>
      <v-col>
        <v-card outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Previsto</div>
              <v-list-item-title class="headline mb-1">
                <h2 class="text-muted">R$ {{ detail.Previsto }}</h2>
              </v-list-item-title>
              <v-list-item-subtitle
                >Valor previsto de bonificação</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-avatar tile>
              <v-icon :size="50" color="blue"
                >mdi-chart-bell-curve-cumulative</v-icon
              ></v-list-item-avatar
            >
          </v-list-item>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Ganho</div>
              <v-list-item-title class="headline mb-1">
                <h2 class="text-muted">R$ {{ detail.Ganho }}</h2>
              </v-list-item-title>
              <v-list-item-subtitle
                >Valor de bonificação ganha</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-avatar tile>
              <v-icon :size="50" color="green"
                >mdi-cash-plus</v-icon
              ></v-list-item-avatar
            >
          </v-list-item>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Debitos</div>
              <v-list-item-title class="headline mb-1">
                <h2 class="text-muted">R$ {{ detail.Debito || 0 }}</h2>
              </v-list-item-title>
              <v-list-item-subtitle>Debito de bonificação</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile>
              <v-icon :size="50" color="red"
                >mdi-cash-minus
              </v-icon></v-list-item-avatar
            >
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="mobile" md="3">
        <v-card outlined height="100%">
          <v-card-title primary-title> Meta</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                ><graphGoal
                  :vendas="detail.Vendas"
                  :metas="detail.Meta"
                  :key="salePerson"
                ></graphGoal
              ></v-col>
            </v-row>
            <v-row>
              <v-col
                ><h1 class="text-center">
                  {{ detail.Vendas }}/{{ detail.Meta }}
                </h1></v-col
              >
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" class="mobile" v-if="detail" md="4">
        <v-card outlined>
          <v-card-title primary-title> Bonificações </v-card-title>
          <v-card-text>
            <graphBonus
              :ganho="detail.Ganha"
              :pendente="detail.Pendente"
              :cancelado="detail.Cancelada"
              :key="salePerson"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6" class="mobile" md="5">
        <v-card outlined>
          <v-card-title primary-title> Por Tecnologia </v-card-title>
          <v-card-text>
            <apexchart
              type="pie"
              data
              :options="tech.graph"
              :series="tech.series"
              v-if="tech"
            ></apexchart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card>
      <v-data-table
        v-if="sales"
        :headers="headers"
        :items="sales"
        :items-per-page="5"
        :key="sales.id"
      >
        <template v-slot:item.taxa="{ item }">
          {{ getTaxa(item.taxa) }}
        </template>
        <template v-slot:item.valor="{ item }">
          R$ {{ item.valor || 0 }}
        </template>
        <template v-slot:item.status="{ item }">
          <strong :class="getColorBonificacao(item.status)">
            {{ getTexBonificacao(item.status) }}
          </strong>
        </template>

        <template v-slot:item.ref_v_stage_contrato="{ item }">
          <v-chip :color="getColor(item.ref_v_stage_contrato)" dark>
            <v-icon left> {{ getIcon(item.ref_v_stage_contrato) }}</v-icon>
            {{ item.ref_v_stage_contrato }}
          </v-chip>
        </template>

        <template v-slot:item.ref_data_venda="{ item }">
          {{ item.ref_data_venda | formatDate }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { formatDate, getProfile } from "../../mixins";

import graphGoal from "./graphGoal";
import graphBonus from "./graphBonus";

export default {
  name: "Profile",
  props: ["seller"],
  mixins: [formatDate, getProfile],
  components: {
    graphGoal,
    graphBonus,
  },
  data: () => {
    return {
      salePerson: null,
      tech: {
        graph: {
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                },
              },
            },
          },
          chart: {
            width: 380,
            type: "donut",
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          labels: [],
        },
        series: [],
      },
      meta: null,
      detail: null,
      sales: "",
      loaded: false,
      radial: null,
      options: {
        colors: ["#57B45E", "#F44336"],

        xaxis: {
          categories: [],
        },
      },
      dados: [
        {
          name: "Vendas Efetuadas",

          data: [],
        },
        {
          name: "Vendas Canceladas",

          data: [],
        },
      ],
      headers: [
        { text: "Vendedor", value: "ref_nome_vendedor" },
        { text: "Cliente", value: "ref_nome_cliente" },
        { text: "Tecnologia", value: "ref_tipo_valida" },
        { text: "Instação", value: "taxa" },
        { text: "Data da Venda", value: "ref_data_venda" },
        { text: "Valor bonificação", value: "valor" },
        { text: "Bonificação", value: "status" },
        { text: "Contrato", value: "ref_v_stage_contrato" },
      ],
    };
  },

  async created() {
    await this.getProfile();
    if (this.seller) {
      this.salePerson = this.seller;
    }

    this.getSales();
    this.getMeta();
    this.getSalesDetail();
    this.getTech();
  },

  methods: {
    getColor(stage) {
      if (stage === "Em Aprovação") return "primary";
      else if (stage === "Cancelado") return "error";
      else if (stage === "Aprovado") return "success";
      else return "grey";
    },
    getTaxa(taxa) {
      if (taxa) return "Instalação Paga";
      if (!taxa) return "Instalação Gratuita";
      else return "-";
    },
    getColorBonificacao(status) {
      if (status === "P") return "blue--text";
      else if (status === "C" || status === "D") return "red--text";
      else if (status === "G" || status === "PG") return "green--text";
      else return "grey--text";
    },
    getIconBonificacao(status) {
      if (status === "P") return "mdi-circle-slice-6";
      else if (status === "C" || status === "D")
        return "mdi-alert-circle-outline";
      else if (status === "G" || status === "PG") return "mdi-check-circle";
      else return "mdi-block-helper";
    },
    getTexBonificacao(status) {
      if (status === "P") return "Pendente";
      else if (status === "C" || status === "D") return "Cancelado";
      else if (status === "G") return "Ganho";
      else if (status === "PG") return "PAGO";
      else return "Não bonificado";
    },
    getIcon(stage) {
      if (stage === "Em Aprovação") return "mdi-circle-slice-6";
      else if (stage === "Cancelado") return "mdi-alert-circle-outline";
      else if (stage === "Aprovado") return "mdi-check-circle";
      else return "grey";
    },
    async getTech() {
      const { data } = await this.$http.get(
        `saleschart?chart=person&id=${this.salePerson}`
      );

      data.forEach((d) => {
        this.tech.graph.labels.push(d.ref_tipo_valida);

        this.tech.series.push(d.count);
      });
    },
    async getMeta() {
      try {
        const result = await this.$http.get(
          `/goal/salerperson/${this.salePerson}`
        );
        this.meta = result.data;
      } catch (error) {
        this.$toast.error("Nenhuma meta foi definida!");
      }
    },
    async getSales() {
      try {
        const result = await this.$http.get(
          `/sales/selepeson/${this.salePerson}`
        );
        this.sales = result.data;
      } catch (error) {
        this.$toast.error("Nenhuma venda localizada!");
      }
    },
    async getSalesDetail() {
      try {
        const result = await this.$http.get(
          `/sales/selepeson/${this.salePerson}/detail`
        );
        this.detail = result.data;
      } catch (error) {
        this.$toast.error("Detalhes de venda não localizados!");
      }
    },
  },
};
</script>
<style>
h2 {
  font-size: 1.875rem;
}

.text-muted {
  color: #98a6ad !important;
}

@media only screen and (max-width: 37.5em) {
  .mobile {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}
</style>