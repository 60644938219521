<template>
  <div>
    <apexchart
      type="bar"
      data
      v-if="series"
      :options="graph"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
var ptbr = require("apexcharts/src/locales/pt-br.json");
export default {
  props: ["ganho", "pendente", "cancelado"],
  data: () => {
    return {
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      graph: {
        colors: ["#57B45E", "#379EFC", "#F44336"],

        xaxis: {
          categories: []
        },
        chart: {
          locales: [ptbr],

          defaultLocale: "pt-br"
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%"
          }
        }
      },
      series: []
    };
  },
  created() {
    this.loadDataGraph();
  },
  methods: {
    async loadDataGraph() {
      this.graph.xaxis.categories.push(
        ptbr.options.months[new Date().getMonth()]
      );
      this.series = [
        {
          name: "Ganha",
          data: [this.ganho]
        },
        {
          name: "Pendente",
          data: [this.pendente]
        },
        {
          name: "Cancelado",
          data: [this.cancelado]
        }
      ];
    }
  }
};
</script>
    
<style>
</style>